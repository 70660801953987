//React
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Actions
import { saveSubscribe } from '../../actions/saveSubscribe'

//Icons
import { FaArrowRight, FaSpinner } from 'react-icons/fa'
import SignalIcon from '../../images/svg/newsletter/piconft-prism-01.svg'

//Redux
const mapStateToProps = ({ isSubscribeLoading, subSubmitted }) => {
  return { isSubscribeLoading, subSubmitted }
}
const mapDispatchToProps = (dispatch) => ({
  saveSubscribe: (email, mode, subscription) => {
    dispatch(saveSubscribe(email, mode, subscription))
  },
})

const Snippet = ({
  context,
  containerClass,
  subClass,
  isSubscribeLoading,
  saveSubscribe,
  subSubmitted,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          phoneComposite: file(
            relativePath: { eq: "featureperspective/feature-canvas-monday.png" }
          ) {
            childImageSharp {
              fixed(width: 220, height: 300, cropFocus: NORTH) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => (
        <div class={containerClass ? containerClass : ''}>
          <div
            class={`post-template-cta my-2 mb-4 px-3 pt-5 rounded-corners gradient-callout-tropical d-flex flex-column ${
              subClass ? subClass : ''
            }`}
          >
            <div class="row d-flex flex-row align-self-center align-items-center justify-content-center">
              <div class="col-md-6">
                <h2 class="white-1 font-weight-normal text-center">
                  Start your free trial
                </h2>
                <p class="lead white-1 text-center line-height-3">
                  Experience Sidepath, free for 7 days
                </p>
                <div class="mb-2 d-flex flex-row align-items-center justify-content-center">
                  <Link
                    to="/start/?c=pro-trial--subcta"
                    class="btn btn-cta-outline"
                  >
                    Start Pro trial <FaArrowRight />
                  </Link>
                </div>
              </div>
              <div class="col-md-6">
                <Img
                  style={{ display: 'block', margin: 'auto' }}
                  fixed={data.phoneComposite.childImageSharp.fixed}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
