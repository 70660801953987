//React
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Actions
import { saveSubscribe } from '../../actions/saveSubscribe'

//Icons
import { FaArrowRight, FaSpinner } from 'react-icons/fa'
import SignalIcon from '../../images/svg/newsletter/piconext-prism-01.svg'

//Redux
const mapStateToProps = ({ isSubscribeLoading, subSubmitted }) => {
  return { isSubscribeLoading, subSubmitted }
}
const mapDispatchToProps = (dispatch) => ({
  saveSubscribe: (email, mode, subscription) => {
    dispatch(saveSubscribe(email, mode, subscription))
  },
})

const Snippet = ({
  context,
  containerClass,
  subClass,
  isSubscribeLoading,
  saveSubscribe,
  subSubmitted,
}) => {
  const [userEmail, setUserEmail] = useState('')
  const [userError, setUserError] = useState(false)
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

  useEffect(() => {
    //like componentDidMount
    if (subSubmitted) {
      setShowConfirmationMessage(true)

      setTimeout(() => {
        setUserEmail('')
        setShowConfirmationMessage(false)
      }, 12000)
    }
  }, [subSubmitted])

  const validateEmail = (email) => {
    const re =
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    return re.test(String(email))
  }
  const handleValidation = () => {
    console.log('validate userEmail')
    console.log(validateEmail(userEmail))
    if (validateEmail(userEmail) === true) {
      saveSubscribe(userEmail, 'enroll', 'signal')
    } else {
      setUserError(true)
    }
  }

  if (context === 'emailSub') {
    return (
      <>
        <div class={containerClass ? containerClass : ''}>
          <div
            class={`post-template-cta my-2 px-3 py-4 rounded-corners gradient-callout-eaglequarter d-flex flex-column ${
              subClass ? subClass : ''
            }`}
          >
            <div class="align-self-center mb-3" style={{ width: 170 }}>
              <SignalIcon />
            </div>
            <h3 class="white-1 font-weight-light text-center">
              Web3 strategies for experience brands
            </h3>
            <p class="lead white-1 text-center">
              Direct from the front lines to your inbox
            </p>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <input
                type="email"
                class={`form-control form-control-email mt-2 mb-4 text-center ${
                  userError ? 'border-1-red-1 is-invalid' : ''
                }`}
                placeholder="Your email"
                value={userEmail}
                onChange={(e) => {
                  // console.log('userEmail')
                  // console.log(userEmail)
                  // console.log('e.target.value')
                  // console.log(e.target.value)
                  setUserEmail(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleValidation()
                  }
                }}
              />

              {subSubmitted && showConfirmationMessage ? (
                <p class="lead p-4 white-1 text-center fade-in line-height-3">
                  Thanks for subscribing!
                  <br />
                  Please check your email for a link to confirm your
                  subscription.
                </p>
              ) : (
                <button
                  class="btn btn-cta-outline px-4"
                  onClick={() => {
                    handleValidation()
                    // saveSubscribe(userEmail, 'enroll', 'signal')
                  }}
                >
                  Subscribe
                  {isSubscribeLoading ? (
                    <FaSpinner
                      size={15}
                      className="icon-spin"
                      style={{ marginLeft: '10px' }}
                    />
                  ) : null}
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <StaticQuery
        query={graphql`
          query {
            phoneComposite: file(
              relativePath: {
                eq: "featureperspective/resortsplus-usecases/resortsplus-maldives-L.png"
              }
            ) {
              childImageSharp {
                fixed(width: 220, height: 280, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            phoneCompositeAuth: file(
              relativePath: {
                eq: "featureperspective/contentnetwork-usecases/contentnetwork-media-sport-soccer-L.png"
              }
            ) {
              childImageSharp {
                fixed(width: 220, height: 280, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={(data) => (
          <div class={containerClass ? containerClass : ''}>
            <div
              class={`post-template-cta my-2 mb-4 px-3 pt-5 rounded-corners gradient-callout-cousteau d-flex flex-column ${
                subClass ? subClass : ''
              }`}
            >
              <div class="row d-flex flex-row align-self-center align-items-center justify-content-center">
                <div class="col-md-6">
                  <h2 class="white-1 font-weight-normal text-center">
                    {/* Get started */}
                    Learn more
                  </h2>
                  <p class="lead white-1 text-center line-height-3">
                    {context === 'nft' ? (
                      <>
                        See how Web3 experiences can help you attract and engage
                        customers in new ways
                        {/* Start creating Web3 experiences with an end-to-end
                        solution for brands */}
                      </>
                    ) : context === 'auth' ? (
                      <>
                        See how Web3 can help you verify your content and
                        protect your brand reputation
                        {/* Start creating Web3 experiences with an end-to-end
                        solution for brands */}
                      </>
                    ) : context === 'climate' ? (
                      <>
                        See how Sustainability Action Reports can help you drive
                        transparency
                        {/* Start creating Web3 experiences with an end-to-end
                        solution for brands */}
                      </>
                    ) : (
                      <>
                        See how Web3 experiences can help you attract and engage
                        customers in new ways
                      </>
                    )}
                  </p>
                  <div class="mb-2 d-flex flex-row align-items-center justify-content-center">
                    {/* <Link
                      to="/start/?c=sd-trial--postcta"
                      class="btn btn-cta-outline"
                    >
                      Free trial <FaArrowRight />
                    </Link> */}
                    {context === 'nft' ? (
                      <Link to="/request-demo" class="btn btn-cta-outline">
                        Request demo <FaArrowRight />
                      </Link>
                    ) : context === 'auth' ? (
                      <Link
                        to="/request-demo-content-authentication"
                        class="btn btn-cta-outline"
                      >
                        Request demo <FaArrowRight />
                      </Link>
                    ) : context === 'climate' ? (
                      <Link
                        to="/request-demo-sustainability-action-report"
                        class="btn btn-cta-outline"
                      >
                        Request demo <FaArrowRight />
                      </Link>
                    ) : (
                      <Link to="/request-demo" class="btn btn-cta-outline">
                        Request demo <FaArrowRight />
                      </Link>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  {context === 'auth' ? (
                    <Img
                      style={{ display: 'block', margin: 'auto' }}
                      fixed={data.phoneCompositeAuth.childImageSharp.fixed}
                    />
                  ) : (
                    <Img
                      style={{ display: 'block', margin: 'auto' }}
                      fixed={data.phoneComposite.childImageSharp.fixed}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
